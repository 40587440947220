<template>
  <v-list-group
    v-model="tournament.active"
    active-class="color3--text"
    append-icon="fas fa-angle-down"
    class="tList"
  >
    <template v-slot:activator>
      <v-list-item-title>{{tournament.name}}</v-list-item-title>
    </template>
    <!-- MY TEAMs -->
      <v-list-item v-if="myTeams.length > 0"
        @click.stop="onMyTeamsClick"
        router
        active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title>My Team{{myTeams.length > 1 ? 's' : ''}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Tournament Home -->
    <v-list-item
        :to="{ name: view.admin ? 'tournament-admin' : 'tournament-brochure', params: {tournamentId: tournament.id} }"
        router
        active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title>Tournament Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Tournament Brochure -->
    <v-list-item
        :to="{ name: 'tournament-brochure', params: {tournamentId: tournament.id} }"
        router
        exact
        active-class="color3--text"
        v-if="view.admin"
      >
        <v-list-item-action class="mr-2"></v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Tournament Brochure</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Tournament Leaderboard -->
      <v-list-item
        v-if="tournament.leaderboard"
        :to="`/tournament/${tournament.id}?tab=leaderboard`"
        router
        active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title>{{tournament.leaderboardTitle}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- STREAMS -->
      <v-list-item
        v-if="tournament.props.includes('streaming') && tournament.mappedStreams.length"
        :to="{ name: 'tournament-streams', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title>Live Streams</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="error">fas fa-signal-stream</v-icon>
        </v-list-item-action>
      </v-list-item>
    <!-- Live Matches -->
      <v-list-item
        v-if="view.adminAndRefs || tournament.showLive"
        :to="{ name: 'tournament-live-matches', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title>Live Matches</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Teams -->
      <v-list-item
        :to="{ name: 'tournament-teams', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title v-if="view.adminAndRefs">Check-in</v-list-item-title>
          <v-list-item-title v-else>Team Locator</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Checklists -->
      <template v-if="view.adminAndRefs">
        <v-list-item
          v-for="c in tournament.checklists"
          :key="c.id"
          :to="{ name: 'tournament-checklist', params: { tournamentId: tournament.id, checklistId: c.id }}"
          router
          exact
          exact-active-class="color3--text"
        >
          <v-list-item-content>
            <v-list-item-title>{{c.name}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    <!-- Players -->
      <v-list-item
        :to="{ name: 'tournament-players', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
        v-if="tournament.isShowcase"
      >
        <v-list-item-content>
          <v-list-item-title>Players</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Courts -->
      <v-list-item
        v-if="view.adminAndRefs && showCourts && false"
        :to="{ name: 'tournament-courts', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title v-if="view.adminAndRefs">Court Manager</v-list-item-title>
          <v-list-item-title v-else>Courts</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Courts 2 -->
      <v-list-item
        v-if="showCourts2"
        :to="{ name: 'tournament-courts-advanced', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title v-if="view.adminAndRefs">Court Manager</v-list-item-title>
          <v-list-item-title v-else>Court Schedule</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Pool Monitor -->
      <v-list-item
        v-if="view.adminAndRefs"
        :to="{ name: 'tournament-pool-monitor', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title v-if="view.adminAndRefs">Pool Monitor</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Divisions -->
    <template v-for="division in divisionTabs">
      <!-- Division Home -->
        <v-list-item
          :key="division.id"
          router
          :to="{name: 'division-nav', params: {tournamentId: tournament.id, divisionId: division.id}}"
          exact
          exact-active-class="color3--text"
        >
          <v-list-item-content>
            <v-list-item-title v-if="division.loading && !division.hydrated">
              <v-progress-linear :indeterminate="true" color="color3"></v-progress-linear>
            </v-list-item-title>
            <v-list-item-title v-else>
              {{ division.name }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="!division.isCS && division.loading">
            <v-progress-circular indeterminate color="color3" size="24"></v-progress-circular>
          </v-list-item-action>
          <v-list-item-action v-if="division.isCS">
            <v-chip small :color="chipColor" v-if="view.admin || division.showTeams">
              {{division.teamCount}}
            </v-chip>
            <v-icon v-else small>fas fa-eye-slash</v-icon>
          </v-list-item-action>
        </v-list-item>

      <template v-if="!(division.loading && !division.hydrated)">
          <v-list-item
            v-for="tab in division.tabs"
            :to="tab.to"
            :key="tab.key"
            router
            exact
            exact-active-class="color3--text"
          >
            <v-list-item-action class="mr-2"></v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{tab.text}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="tab.count || !tab.public">
              <v-chip small :color="chipColor" v-if="tab.count">
                {{tab.count}}
              </v-chip>
              <v-icon v-else small>fas fa-eye-slash</v-icon>
            </v-list-item-action>
          </v-list-item>

        <!-- ROUNDS -->
          <template v-for="round in division.rounds">
            <template v-if="round.published || view.admin">
              <!-- POOLS -->
                <v-list-item
                  v-if="round.poolPlay"
                  :to="round.flight && round.flight.pools && round.flight.pools.length === 1 && round.flight.pools[0].id && (['Matches', 'Friday', 'Saturday', 'Sunday'].includes(round.name) || round.freePlay) ? {
                    name: 'pool-sheet',
                    params: {tournamentId: tournament.id, divisionId: division.id, dayId: round.id, poolId: round.flight.pools[0].id}
                  } :{
                    name: 'pools-home',
                    params: {tournamentId: tournament.id, divisionId: division.id, dayId: round.id}
                  }"
                  :key="`pools_${division.id}_${round.id}`"
                  router
                  active-class="color3--text"
                >
                  <v-list-item-action class="justify-center mr-2">
                    <v-icon small v-if="round.flight && round.flight.complete">far fa-check fa-fw</v-icon>
                    <v-icon small v-else-if="round.flight && round.flight.started">fas fa-caret-right fa-fw</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="align-center d-flex justify-space-between">
                      {{round.name}}{{view.admin && round.lockReady ? '*' : ''}} {{view.admin && tournament.props.includes('poolTypes') ? round.poolTypes : ''}}
                      <span class="caption" v-if="view.admin && round.waitingOn">
                        wait {{round.waitingOn}}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="(view.admin || round.published) && division.hydrated">
                    <v-chip small :color="chipColor">
                      {{(round.name === 'Matches' && round.pools.length === 1) || round.freePlay ? round.poolMatches.length : round.pools.length}}
                      {{!round.freePlay && view.admin && round.uncourtedPools.length > 0 ? '*' : ''}}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              <!-- BRACKETS -->
                <template v-if="!round.poolPlay && (view.admin || round.published)">
                  <v-list-item
                    inactive
                    :key="`bracket_header${division.id}_${round.id}`"
                    v-if="round.bracket && round.bracket.isDouble"
                  >
                    <v-list-item-content class="ml-6 grey--text">
                      {{round.name}}
                    </v-list-item-content>
                  </v-list-item>
                  <!-- Winners -->
                  <v-list-item
                    :to="{name: 'bracket-home', params: {tournamentId: tournament.id, divisionId: division.id, dayId: round.id}}"
                    :key="`bracket${division.id}_${round.id}`"
                    router
                    exact-active-class="color3--text"
                  >
                  <!-- WB -->
                    <v-list-item-action class="justify-center mr-2">
                      <template v-if="division.hydrated">
                        <v-icon small v-if="(round.bracket && round.bracket.winnersStatus.complete) || round.locked">far fa-check fa-fw</v-icon>
                        <v-icon small v-else-if="round.bracket && round.bracket.winnersStatus.started">fas fa-caret-right fa-fw</v-icon>
                      </template>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{!round.bracket || !round.bracket.losers.length || round.bracket.hasPlaceBracket ? round.name : 'Winners Bracket'}}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="round.bracket">
                      <v-list-item-action-text v-if="round.bracket.winnersStatus.started && !round.bracket.winnersStatus.complete && !round.locked">
                        In {{round.bracket.winnersStatus.current}}
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <!-- LB -->
                  <v-list-item
                    v-if="round.bracket && round.brackets[0].losers.length"
                    :to="{name: 'bracket-contenders', params: {tournamentId: tournament.id, divisionId: division.id, dayId: round.id}}"
                    :key="`bracket${division.id}_${round.id}_LB`"
                    router
                    exact
                    exact-active-class="color3--text"
                  >
                    <v-list-item-action class="justify-center mr-2">
                      <v-icon small v-if="round.bracket.losersStatus.complete">far fa-check fa-fw</v-icon>
                      <v-icon small v-else-if="round.bracket.losersStatus.started">fas fa-caret-right fa-fw</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="pl-3">
                        {{round.bracket.losersName}}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="view.admin || round.published">
                      <v-list-item-action-text v-if="round.bracket.losersStatus.started && !round.bracket.losersStatus.complete">
                        In {{round.bracket.losersStatus.current}}
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </template>

            </template>
          </template>
      </template>
    </template>
    <my-teams-dialog
      :teams="myTeams"
      :dialog="dialog"
      @dialog-change="dialog = $event"
    ></my-teams-dialog>
  </v-list-group>
</template>

<script>
import { mapGetters } from 'vuex'
import { getHsp } from '@/classes/Dark'
import { firstBy } from 'thenby'
import uniq from 'lodash.uniq'
const MyTeamsDialog = () => import('@/components/Team/Rostered/MyTeamsDialog.vue')

export default {
  data () {
    return {
      dialog: false,
      courtManager: false
    }
  },
  computed: {
    ...mapGetters(['user', 'tournament', 'view', 'color2IsDark', 'theme', 'userTeams', 'getTeam']),
    chipColor () {
      const hsp1 = getHsp(this.theme.color2)
      const hsp2 = getHsp(this.theme.color2Text)
      return hsp1 < hsp2 ? 'color2 color2Text--text' : 'color2Text color2--text'
    },
    showCourts () {
      return !!this.publicDivisions.find(f => !f.isCS)
    },
    showCourts2 () {
      return !!this.publicDivisions.find(f => !f.isCS && !f.isLeague) && (this.tournament.publicRounds.length > 0 || this.view.adminAndRefs)
    },
    publicDivisions () {
      var divs = this.tournament && this.tournament.adminDivisions
      divs.push(...this.tournament.divisions.filter(f => f.isXrefs))
      var jProps = this.tournament && this.tournament.jProps && this.tournament.jProps.divisions
      return divs && jProps ? divs.filter(f => !(jProps[f.id] && jProps[f.id].hide)) : divs
    },
    divisionTabs () {
      return this.publicDivisions.map(d => {
        return {
          loading: d.loading,
          hydrated: d.hydrated,
          id: d.id,
          name: d.name,
          show: this.view.admin || !d.isCS,
          tabs: [
            {
              text: 'Settings',
              to: { name: 'division-home', params: { tournamentId: this.tournament.id, divisionId: d.id } },
              show: this.view.admin,
              key: `settings_${d.id}`
            },
            {
              text: 'Round Manager',
              to: { name: 'division-rounds', params: { tournamentId: this.tournament.id, divisionId: d.id } },
              show: this.view.admin && !d.isCS,
              key: `rounds_${d.id}`
            },
            {
              text: `${d.acceptanceOnly ? 'Accepted ' : ''}${d.teamLabel}${this.view.admin && d.pools.length > 0 && d.poollessTeamIds.length > 0 ? '*' : ''}`,
              to: { name: 'division-teams', params: { tournamentId: this.tournament.id, divisionId: d.id } },
              show: this.view.admin || (!d.isCS && (!d.acceptanceOnly || d.teamCount)),
              key: `teams_${d.id}`,
              count: this.view.admin || d.showTeams ? d.teamCount : null,
              public: d.showTeams
            },
            {
              text: d.acceptanceOnly ? `${this.view.admin || d.teamCount ? 'Pending ' : ''}Teams` : 'Waitlist',
              to: { name: 'division-waitlist', params: { tournamentId: this.tournament.id, divisionId: d.id } },
              show: d.waitlistTeams.length && !d.isCS && !(this.view.public && d.hideWaitlist),
              key: `waitlist_${d.id}`,
              count: this.view.admin || d.showTeams ? d.waitlistTeams.length : null,
              public: d.showTeams
            },
            ...(d.standings || []).map((s, i) => {
              return {
                text: s.title,
                to: `/tournament/${this.tournament.id}/division/${d.id}/standings/${i}`,
                show: this.view.admin || s.published,
                key: `standings_${d.id}_${i}`,
                public: s.published
              }
            })
          ].filter(f => f.show),
          rounds: d.isCS ? null : d.days
        }
      }).filter(f => f.show)
    },
    myTeams () {
      const a = []
      const t = uniq(this.vblTeams || this.userTeams)
      // const t = this.userTeams
      if (this.tournament && t.length > 0) {
        t.forEach(t => {
          var team = this.getTeam(t)
          var division = this.tournament.getDivisionByTeam(t)
          if (team && !team.isDeleted) {
            a.push({
              name: team.name,
              team: team,
              division: division,
              to: { name: 'division-team', params: { tournamentId: this.tournament.id, teamId: t }, query: { tab: 'schedule' } }
            })
          }
        })
      }
      // console.log(a)
      return a.sort(firstBy('name'))
    },
    vblTeams () {
      return this.view && this.view.admin && this.tournament && this.tournament.activeTeams.map(m => m.id)
    }
  },
  methods: {
    onMyTeamsClick () {
      if (this.myTeams.length === -1) {
        this.$router.push(this.myTeams[0].to)
      } else {
        this.dialog = true
      }
    },
    goto (t) {
      this.$router.push(t.to)
      this.dialog = false
    }
  },
  components: {
    MyTeamsDialog
  }
}
</script>
<style>
.tList .v-list-group__header {
  padding-left: 8px !important;
  padding-right: 2px !important;
}
</style>
